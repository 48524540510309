import React, { PropsWithChildren } from "react";

interface IProps {

}

export const Footer = (props: PropsWithChildren<IProps>) => {
    return (
        <footer>© {new Date().getFullYear()} Like a Photon Creative | <a href={"/privacy-policy"}>Privacy Policy</a> | <a href={"/terms-and-conditions"}>Terms &amp; Conditions</a></footer>
    );
};
