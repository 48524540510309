import React, { PropsWithChildren } from "react";

interface IProps {
    label: string;
}

export const Title = (props: PropsWithChildren<IProps>) => {
    const { label } = props;

    return <header>
        <h1>{label}</h1>
    </header>;
};
